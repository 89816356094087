// Imports
// ------------
import React from 'react';
import Error404 from '@parts/Error404';
import AnimatedLogo from '@parts/AnimatedLogo';

// Component
// ------------
const PageNotFound = () => {

    return (
        <Error404>
            <AnimatedLogo />
        </Error404>
    );
}
export default PageNotFound;

// GraphQL
// ------------
