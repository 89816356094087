// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';
import { breakup, Section, H1 } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)(props => css`
    position: relative;
    height: ${props.theme.vhs};
    background: ${props.theme.colors.brand.bc1150};
    overflow: hidden;
`);


export const Background = styled.div(props => css`
    /* ${props.theme.talign} */
`);


const turn = keyframes`
    0% { transform:  rotateZ(0deg);}
    100% { transform: rotatez(360deg);}
`;

export const Rotation = styled.div(props => css`
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    width: ${props.w}rem;
    height: ${props.h}rem;

    transform:  translate(-50%, -50%);

    transition:
        transform 1.1s ${props.theme.easing.bezzy},
        opacity 0.5s linear;

    transition-delay: 0.25s;
`);

export const Mark = styled.div(props => css`
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    animation: ${turn} 10s linear both infinite;

    svg {
        fill: ${props.theme.colors.brand.bc1150};
        stroke: ${props.theme.colors.brand.bc1};
    }
`);


export const Title = styled(H1)(props => css`
    ${props.theme.talign}
    z-index: 2;
    color: ${props.theme.colors.global.white};
    width: 100%;
    text-align: center;


    ${breakup.large`
        display: flex;
        flex-flow: column;
    `}

    span {
        display: inline;

        a {
            position: relative;
            display: inline-block;

            &:after {
                content: "";
                position: absolute;
                bottom: 0; left: 0; right: 0;
                height: 3px;
                background: ${props.theme.colors.global.white};
            }
        }
    }
`);