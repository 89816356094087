// Imports
// ------------
import React from 'react';
import { observer } from 'mobx-react-lite';
import { menuState } from '@states';
import logo from '@images/animated-logo.svg';

// Styles
// ------------
import { Jacket, Rotation, Mark } from './styles';

// Component
// ------------
const AnimatedLogo = () => (
    <Jacket>
        <Rotation isVisible={menuState.isOpen}>
            <Mark isVisible={menuState.isOpen}>
                <img src={logo} alt="Seek • Animated Graphic" />
            </Mark>
        </Rotation>
    </Jacket>
);

export default observer(AnimatedLogo);