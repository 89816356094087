// Imports
// ------------
import React from 'react';

// Styles
// ------------
import { Jacket } from './styles';

// Component
// ------------
const LogoIcon = ({ copyNumber, degree, menuOpen, w, h }) => {
    let degreeAmount = copyNumber * degree;

    return (
        <Jacket
            style={{ zIndex: copyNumber }}
            menuOpen={menuOpen}
            w={w / 10}
            h={h / 10}
            width={w}
            height={h}
            viewBox={`0 0 ${w} ${h}`}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            rotation={degreeAmount}
            className="logo-icon"
        >
            <path d="M883.914 663.017L662.991 883.916C600.865 821.797 550.321 819.186 442.069 819.186C321.566 819.186 156.342 819.186 0.223877 662.883L221.146 441.983C283.206 504.103 333.75 506.781 442.069 506.714C562.505 506.714 727.595 506.714 883.914 663.017Z" />
            <path d="M220.832 -5.58108e-05L441.854 220.999L221.022 441.808C162.436 383.229 129.507 303.788 129.471 220.944C129.436 138.099 162.297 58.6299 220.832 -5.58108e-05V-5.58108e-05Z" />
            <path d="M441.957 1105L662.978 884.002C692.003 913.024 715.027 947.478 730.735 985.397C746.443 1023.32 754.529 1063.96 754.529 1105C754.529 1146.04 746.443 1186.69 730.735 1224.6C715.027 1262.52 692.003 1296.98 662.978 1326V1326L441.957 1105Z" />
        </Jacket>
    )
}

export default React.memo(LogoIcon);