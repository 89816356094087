// Imports
// ------------
import styled, { css, keyframes } from 'styled-components';
import { Section } from '@tackl';

// Exports
// ------------
export const Jacket = styled(Section)`
    position: absolute;
    top: 0; left: 0; right: 0; bottom: 0;
    z-index: 0;
    overflow: hidden;
    pointer-events: none;
`;


const turn = keyframes`
    0% { transform:  rotateZ(0deg);}
    100% { transform: rotatez(360deg);}
`;

export const Rotation = styled.div(props => css`
    position: absolute;
    top: 50%;
    left: -40%;
    width: 139.8rem;
    height: 139.8rem;

    opacity: ${props.isVisible ? 1 : 0};

    transform:  translateY(-50%);

    transition:
        /* transform 1.1s ${props.theme.easing.bezzy}, */
        opacity 0.5s linear;

    /* transition-delay: 0.25s; */
`);

export const Mark = styled.div(props => css`
    position: absolute;
    top: 0; left: 0;
    width: 100%;
    height: 100%;
    animation: ${turn} 10s linear both infinite;
    /* animation-play-state: ${props.isVisible ? 'running' : 'paused'}; */

    img {
        width: 139.8rem;
        height: 139.8rem;
    }
`);
