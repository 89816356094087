// Imports
// ------------
import styled, { css } from 'styled-components';

// Exports
// ------------
export const Jacket = styled.svg(props => css`
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%) rotate(${props.rotation}deg);
    transform-origin: center center;

    display: block;

    width: ${props.w}rem;
    height: ${props.h}rem;
    fill: ${props.theme.colors.brand.bc2};
    stroke: ${props.theme.colors.brand.bc290};
    stroke-width: 1px;
    pointer-events: none;
`);
