// Imports
// ------------
import React from 'react';
import LogoIcon from '@parts/AnimatedLogo/LogoIcon';
import { Link } from 'gatsby';

// Styles
// ------------
import { Jacket, Rotation, Mark, Title } from './styles';

// Component
// ------------
const Error404 = () => {
    // NOTE • Calcs
    const number = 96;
    const degree = 180 / number;

    const w = `884`;
    const h = `1326`;

    return (
        <Jacket>
            <Rotation w={w / 10} h={h / 10}>
                <Mark>
                    {[...Array(number)].map((e, i) => (
                        <LogoIcon
                            key={i}
                            copyNumber={i + 1}
                            degree={degree}
                            w={w} h={h}
                        />
                    ))}
                </Mark>
            </Rotation>

            <Title>
                <span>It seems somebody is lost </span>
                <span>let’s get you <Link to="/">home</Link></span>
            </Title>
        </Jacket>
    );
}

export default Error404;